import * as React from "react";
import { Link } from "gatsby";


const NotFoundPage = () => {
  return (
      <div className="min-h-screen pt-16 pb-12 flex flex-col bg-white">
          <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
              <div className="flex-shrink-0 flex justify-center">
                  <a href="/" className="inline-flex">
                      <img className="w-auto h-10 sm:h-12 lg:h-16" src={'/logo.svg'} alt="Orsilli" width="197" height="72"/>
                  </a>
              </div>
              <div className="py-16">
                  <div className="text-center">
                      <p className="title-section mb-1">404</p>
                      <h1 className="title-section">Pagina non trovata</h1>
                      <p className="text-base sm:text-lg mt-1 font-normal opacity-60">Spiacenti, non siamo riusciti a trovare la pagina che stai cercando.</p>
                      <div className="mt-6">
                          <Link to="/" className="primary-button w-full max-w-[250px] lg:!mt-auto">
                              Torna alla  home
                          </Link>
                      </div>
                  </div>
              </div>
          </main>
      </div>
  )
}

export default NotFoundPage
